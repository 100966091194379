import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
//
import AllRoutes from './routes';
//
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={5000}
        theme="light"
        position="bottom-center"
        draggable
      />
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
