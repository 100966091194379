import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import React from 'react';
import './styles.css';

const TermosDialog = ({nomeMvno, children}) => (
  <Dialog.Root>
    <Dialog.Trigger asChild>
      {children}
    </Dialog.Trigger>
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent">
        <Dialog.Title className="DialogTitle">Termo de Aceite para Mudança na Recarga de Linha</Dialog.Title>
        <Dialog.Description className="DialogDescription">
        Eu, usuário do serviço fornecido pela Operadora Virtual, doravante denominada "Empresa", reconheço e aceito as seguintes condições relacionadas à mudança na recarga de linha:
        </Dialog.Description>
        <Dialog.Description className="DialogDescription DialogTopic" asChild>
        <ol>
          <li>Entendimento da Mudança: Eu entendo que a Empresa implementou uma atualização em seu sistema de recarga de linha, e a partir de agora, qualquer recarga feita antes do vencimento será processada somente na data de renovação.</li>
          <li>Concordância com a Nova Política: Eu concordo em cumprir e respeitar a nova política de recarga de linha estabelecida pela Empresa.</li>
          <li>Informações Adicionais: Estou ciente de que essa mudança pode impactar minha experiência como cliente, e estou disposto a buscar esclarecimentos adicionais junto à Empresa, se necessário.</li>
        </ol>
        </Dialog.Description>
        <Dialog.Description className="DialogDescription">
        Ao aceitar este termo, declaro que li, compreendi e concordo com as condições estabelecidas para a mudança na recarga de linha.
        </Dialog.Description>
        
        <Dialog.Close asChild>
          <button className="IconButton" aria-label="Close">
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default TermosDialog;