import React from 'react'
import { Routes, Route } from 'react-router-dom'
//
import Fatura from '../pages/Fatura';


export default function AllRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Fatura/>}/>
    </Routes>
  )
}
