import React from 'react';
//
import LoadingCircle from '../LoadingCircle';
//
import styles from './botao.module.css';

export default function Botao({
    icon,
    text,
    type,
    onClick,
    disabled,
    color,
    style,
    href,
    target,
    loading,
}) {

    if (loading) {
        return (
            <button
                className={
                    styles['botao'] + ' ' +
                    styles[color ? color : 'primary']
                }
                type={'button'}
                onClick={onClick}
                style={style}
                disabled
            >
                <LoadingCircle />
            </button>
        );
    }

    if (type === 'url') {
        return (
            <a
                className={styles['botao'] + ' ' + styles[color ? color : 'primary']}
                href={href}
                target={target}
                style={style}
                rel="noreferrer"
            >
                {icon}
                {text &&
                    <span style={icon ? { marginLeft: '0.5rem' } : {}}>
                        {text}
                    </span>
                }
            </a>
        );
    } else {
        return (
            <button
                className={
                    styles['botao'] + ' ' +
                    styles[color ? color : 'primary']
                }
                type={type ? type : 'button'}
                onClick={onClick}
                disabled={disabled}
                style={style}
            >
                {icon}
                {text &&
                    <span style={icon ? { marginLeft: '0.5rem' } : {}}>
                        {text}
                    </span>
                }
            </button>
        );
    }
}
