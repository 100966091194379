import { getDateDiffInDays } from "./getDateDiffInDays";

export function getDateDiffText(data){
    const diff = getDateDiffInDays(data);

    if(diff > 0){
        return `(vence daqui ${diff.toString()} dias)`
    }

    if(diff < 0){
        return `(vencido há ${diff.toString().slice(1)} dias)`
    }

    if(diff === 0){
        return `(vence hoje)`
    }
}