export function toCurrency(numero) {


    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    if(typeof numero === 'string'){
        
        let num = parseFloat(numero);

        const numFormatado = formatter.format(num);
        return numFormatado;

    }else{
        
        let num = numero;

        const numFormatado = formatter.format(num);
        return numFormatado;
    }
}