import React from 'react'

export default function Base64Img({ data, alt, className, style }) {

    return (
        <img
            src={`data:image/*;base64,${data}`}
            alt={alt ? alt : 'Imagem'}
            className={className}
            style={style}
        />
    )
}
