import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { mask } from "remask";
import app from "../../../package.json";
//
import { formatDate } from "../../functions/formatDate";
import { getDateDiffText } from "../../functions/getDateDiffText";
import { toCurrency } from "../../functions/toCurrency";
//
import axios from "axios";
import apiPlay from "../../services/apiPlay";
//
import Base64Img from "../../components/Base64Img";
import LoadingCircle from "../../components/LoadingCircle";
import RadioInput from "../../components/RadioInput";
import StatusFatura from "../../components/StatusFatura";
import TelaBoleto from "../../components/TelaBoleto";
import TelaCredito from "../../components/TelaCredito";
import TelaPix from "../../components/TelaPix";
import TelaRecorrencia from "../../components/TelaRecorrencia";
//
import { BiTransferAlt } from "react-icons/bi";
import { IoAlert, IoAlertCircle, IoBarcodeSharp, IoCardOutline, IoReload } from "react-icons/io5";
//
import Botao from "../../components/Botao";
import TermosDialog from "../../components/TermosDialog";
import styles from "./fatura.module.css";

export default function Fatura() {
  const [searchParams] = useSearchParams();

  const payId = searchParams.get("payid");

  //STATES
  const [formaPagamento, setFormaPagamento] = useState("0");
  const [loadingDados, setLoadingDados] = useState(true);
  const [fatura, setFatura] = useState({});
  const [showMais, setShowMais] = useState(false);
  const [acceptedTerm, setAcceptedTerm] = useState(false);
  const [lockCheckboxTerm, setLockCheckboxTerm] = useState(false);
  const [isRecActive, setIsRecActive] = useState(false);
  const [cartoes, setCartoes] = useState([])

  const isAdicional = fatura.payment === "ADICIONAL";

  const handleClickProsseguir = () => {
    setAcceptedTerm(true);
    setLockCheckboxTerm(true);
  };

  //FUNCTIONS
  function isPaid() {
    if (
      fatura.status === "RECEIVED" ||
      fatura.status === "RECEIVED_IN_CASH" ||
      fatura.status === "CONFIRMED"
    ) {
      return true;
    }

    return false;
  }

  //CICLOS

  useEffect(() => {
    console.log(`Fatura v.${app.version}`);
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    setLoadingDados(true);


    function cardsRecorrencia(cpf, companyid, msisdn) {
      let dados = {
        cpf,
        companyid,
        msisdn
      };

      apiPlay
        .post("/api/recorrencia/listaCartoes", dados)
        .then((response) => {
          setIsRecActive(response.data.status)
          setCartoes(response.data.cartoes)
        })
        .catch((error) => {
          console.log("/api/recorrencia/listaCartoes | error", error);
        });
    }



    let dados = {
      payid: payId,
    };

    apiPlay
      .post("/api/asaasfatura", dados, { cancelToken: cancelToken.token })
      .then((response) => {
        setFatura(response.data);
        cardsRecorrencia(response.data.cpf, response.data.companyid, response.data.msisdn)
        setLoadingDados(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request Cancelado");
          return;
        }

        console.log(error);
      });

    return () => {
      cancelToken.cancel();
    }; // eslint-disable-next-line
  }, []);

  console.log(cartoes)

  //BODY

  if (loadingDados) {
    return (
      <div className={styles["loading"]}>
        <LoadingCircle />
      </div>
    );
  }

  return (
    <div
      className={styles["container"]}
      style={
        isPaid()
          ? {
            background:
              "linear-gradient(to bottom, var(--successL) 0%, var(--bk1) 50%)",
          }
          : fatura.status === "OVERDUE"
            ? {
              background:
                "linear-gradient(to bottom, var(--errorL) 0%, var(--bk1) 50%)",
            }
            : {}
      }
    >
      <div className={styles["fatura"]}>
        <header className={styles["header"]}>
          {fatura.logo && (
            <div className={styles["header-logo-wrapper"]}>
              <Base64Img data={fatura.logo} className={styles["header-logo"]} />
            </div>
          )}
          <div className={styles["header-texts"]}>
            <span className={styles["empresa"]}>
              {fatura.nomeempresa && fatura.nomeempresa.toUpperCase()}
            </span>
          </div>
        </header>

        <div className={styles["status-pagamento"]}>
          <StatusFatura fatura={fatura} />
          <a href={`https://atendimento.operadora.app.br/?companyId=${fatura.companyid}`} target='_blank' rel='noopener noreferrer'>
            Reportar um problema
          </a>
        </div>

        <div className={styles["container-dados"]}>
          <span className={styles["title-absolute"]}>
            Dados da fatura - {fatura.invoiceNumber && fatura.invoiceNumber}
          </span>

          <div className={styles["dados-fatura"]}>
            <div className={styles["box-dados-fatura"]}>
              <span className={styles["subtitle"]}>Valor</span>
              <span
                className={styles["valores-dados"]}
                style={
                  isPaid()
                    ? { color: "var(--successM)" }
                    : fatura.status === "OVERDUE"
                      ? { color: "var(--errorM)" }
                      : {}
                }
              >
                {fatura.value ? toCurrency(fatura.value) : "R$ 0,00"}
              </span>
            </div>

            <div className={styles["box-dados-fatura"]}>
              <span className={styles["subtitle"]}>Data de vencimento</span>
              <span
                className={styles["valores-dados"]}
                style={
                  isPaid()
                    ? { color: "var(--successM)" }
                    : fatura.status === "OVERDUE"
                      ? { color: "var(--errorM)" }
                      : {}
                }
              >
                {fatura.dueDate
                  ? formatDate(`${fatura.dueDate} 00:00:00`.replace(/-/g, "/"))
                  : "??/??/??"}
              </span>
              <span className={styles["subtitle"]}>
                {fatura.dueDate &&
                  !isPaid() &&
                  getDateDiffText(fatura.dueDate + " 00:00:00")}
              </span>
            </div>
          </div>

          {fatura.contafatura && (
            <>
              <span className={styles["subtitle"]}>Descrição</span>

              <span className={styles["descricao"]}>{fatura.description}</span>
            </>
          )}
        </div>

        <div className={styles["dados-comprador"]}>
          <span className={styles["title-absolute"]}>Dados</span>

          <div className={styles["info-comprador"]}>
            <div className={styles["subtitle"]}>Nome:</div>
            <span>{fatura.nome || "-"}</span>
          </div>

          <div className={styles["info-comprador"]}>
            <div className={styles["subtitle"]}>CPF/CNPJ:</div>
            <span>
              {fatura.cpf
                ? mask(fatura.cpf, ["999.999.999-99", "99.999.999/9999-99"])
                : "-"}
            </span>
          </div>

          <div className={styles["info-comprador"]}>
            <div className={styles["subtitle"]}>E-mail:</div>
            <span>{fatura.email || "-"}</span>
          </div>

          <div className={styles["info-comprador"]}>
            <div className={styles["subtitle"]}>Número da Linha:</div>
            <span>
              {fatura.msisdn
                ? mask(fatura.msisdn.slice(2), "(99) 99999-9999")
                : "-"}
            </span>
          </div>
          {fatura.payment === "ADICIONAL" ? (
            <div className={styles["info-comprador"]}>
              <div className={styles["subtitle"]}>Descrição:</div>
              <span>{fatura.description || "-"}</span>
            </div>
          ) : (
            <div className={styles["info-comprador"]}>
              <div className={styles["subtitle"]}>Plano:</div>
              <span>{fatura.plandescription || "-"}</span>
            </div>
          )}
        </div>

        {!isAdicional && (
          <>
            <span className={styles["title-absolute-termos"]}>
              Para prosseguir com o pagamento, aceite os termos
            </span>
            <div className={styles["termos-e-condicoes"]}>
              <input
                type="checkbox"
                id="termos-e-condicoes"
                name="termos-e-condicoes"
                checked={isPaid() ? true : acceptedTerm}
                onChange={(e) => setAcceptedTerm(e.target.checked)}
                disabled={isPaid() || lockCheckboxTerm}
              />
              <div>
                <label htmlFor="termos-e-condicoes">
                  Eu li e concordo com estes{" "}
                </label>
                <TermosDialog nomeMvno={fatura.nomeempresa}>
                  <span>termos e condições</span>
                </TermosDialog>
                .
              </div>
            </div>
            {!isPaid() && (
              <div style={{ marginLeft: "12px" }}>
                <Botao
                  text="Prosseguir"
                  color={lockCheckboxTerm ? "" : "primary-g"}
                  disabled={!acceptedTerm}
                  onClick={handleClickProsseguir}
                />
              </div>
            )}
          </>
        )}

        {!isPaid() && (lockCheckboxTerm || isAdicional) && (
          <div className={styles["selecione-pagamento"]}>
            <span className={styles["title-absolute"]}>
              Selecionar a forma de pagamento
            </span>

            <div className={styles["btns-pagamento"]}>
              <>
                <RadioInput
                  icon={<BiTransferAlt />}
                  text="PIX"
                  name="forma-pagamento"
                  id="pixFP"
                  value="0"
                  state={formaPagamento}
                  setState={setFormaPagamento}
                  color="primary-g"
                />

                {!showMais ? (
                  <Botao
                    text="Mais Opções..."
                    color="primary-g"
                    onClick={() => {
                      setShowMais((prev) => !prev);
                    }}
                  />
                ) : (
                  <>
                    <RadioInput
                      icon={<IoBarcodeSharp />}
                      text="Boleto Bancário"
                      name="forma-pagamento"
                      id="boletoFP"
                      value="1"
                      state={formaPagamento}
                      setState={setFormaPagamento}
                      color="primary-g"
                    />

                    <RadioInput
                      icon={<IoCardOutline />}
                      text="Cartão de Crédito"
                      name="forma-pagamento"
                      id="CreditoFP"
                      value="2"
                      state={formaPagamento}
                      setState={setFormaPagamento}
                      color="primary-g"
                    />
                  </>
                )}
              </>

              {fatura.msisdn && (
                <RadioInput
                  icon={<IoReload />}
                  text="Cobrança Recorrente"
                  name="forma-pagamento"
                  id="CobrancaRecorrenteFP"
                  value="3"
                  state={formaPagamento}
                  setState={setFormaPagamento}
                  color="primary-g"
                />
              )}
            </div>

            {formaPagamento === '3' && (
              <div className={styles['alert-recurrence']}>
                <div>
                  <IoAlertCircle size={24} color="#facc15" />
                </div>

                <p>A cobrança será feita no seu <strong>primeiro cartão cadastrado</strong>. Caso não seja possível, tentaremos no <strong>segundo</strong> e, se necessário, no <strong>terceiro</strong>.</p>
              </div>
            )}

            {formaPagamento === "0" && <TelaPix fatura={fatura} />}
            {formaPagamento === "1" && <TelaBoleto fatura={fatura} />}
            {formaPagamento === "2" && (
              <TelaCredito fatura={fatura} payId={payId} />
            )}
            {formaPagamento === "3" && (
              <TelaRecorrencia
                fatura={fatura}
                payId={payId}
                cartoesInitial={cartoes}
                isRecActive={isRecActive}
              />
            )}
          </div>
        )}

        <footer className={styles["footer"]}>
          <span className={styles["info-fatura"]}>
            Esta cobrança é de responsabilidade única e exclusiva de{" "}
            <b>
              {fatura.nomeempresa?.toUpperCase()} | CNPJ{" "}
              {fatura.cnpj && mask(fatura.cnpj, ["99.999.999/9999-99"])}
            </b>
            .
          </span>

          {/* <div>
            <span className={styles["txt-link"]}>
              Esta fatura é intermediada por{" "}
              <b className={styles["copyright"]}>
                PlayMóvel TELEFONIA MOVEL CELULAR LTDA - 33.093.462/0001-50
              </b>
            </span>
          </div> */}
        </footer>
      </div>
    </div>
  );
}
