import axios from "axios";

const apiPlay = axios.create({
    baseURL: "https://sistema.playmovel.com.br"
})

// const apiPlay = axios.create({
//     baseURL: "https://dev.operadora.app.br"
// })


export default apiPlay;