import React from 'react';
import Barcode from 'react-barcode/lib/react-barcode';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
//
import Botao from '../Botao';
//
import styles from './TelaBoleto.module.css';

export default function TelaBoleto({ fatura }) {

  const idVerBoleto = fatura.link.split('/')[5]

  //BODY
  return (
    <section className={styles['container']}>

      <span className={styles['title']}>
        Boleto bancário para pagamento
      </span>

      <div className={styles['boleto-container']}>

        <div className={styles['boleto']}>

          <CopyToClipboard
            text={fatura.codigoboleto}
            onCopy={() => { toast.info('Código copiado para a área de transferência!') }}
          >
            <Botao
              type='button'
              onClick={() => { }}
              text='Copiar Código de Barras'
              color='primary-g'
            />
          </CopyToClipboard>

          <span className={styles['cod-boleto']}>
            {fatura.codigoboleto}
          </span>

          <Barcode
            width={1.1}
            height={70}
            fontSize={13}
            displayValue={false}
            value={fatura.barcode}
          />

          <div className={styles['btns-boleto']}>
            <Botao
              type='url'
              href={`https://www.asaas.com/b/preview/${idVerBoleto}`}
              target='_blank'
              text='Visualizar Boleto'
            />

            <Botao
              type='url'
              href={fatura.link}
              target='_blank'
              text='Imprimir Boleto'
            />
          </div>

        </div>

      </div>

    </section>
  )
}
