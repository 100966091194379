import React from 'react'
//
import styles from './checkbox.module.css';

export default function Checkbox({ label, id, checked, onChange, disabled, style }) {
    return (
        <section className={styles['checkbox-container']}>
            <div className={styles['cntr']}>
                <input
                    type='checkbox'
                    id={id}
                    className={styles['hidden-xs-up']}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                />
                <label
                    htmlFor={id}
                    className={styles['checkbox']}
                    style={style}
                ></label>
            </div>
            {label &&
                <label
                    className={styles['label']}
                    htmlFor={id}
                >
                    {label}
                </label>
            }
        </section>
    )
}
