import React from 'react'
//
import styles from './radio-input.module.css';

export default function RadioInput({
  name,
  id,
  value,
  state,
  setState,
  disabled,
  style,
  icon,
  text,
  color,
}) {
  return (
    <>
      <input
        type='radio'
        name={name}
        id={id}
        value={value}
        className={
          styles['h-radio'] + ' ' +
          styles[color ? color : 'primary']
        }
        onChange={(e) => { setState(e.target.value) }}
        disabled={disabled}
        checked={state === value}
      />
      <label
        htmlFor={id}
        className={styles['lbl-radio']}
        style={style}
      >
        {icon}
        {text &&
          <span style={icon ? { marginLeft: '0.5rem' } : {}}>
            {text}
          </span>
        }
      </label>
    </>
  )
}
