import React from 'react';
//
import styles from './input.module.css';

export default function Input({ type, label, value, onChange, required, disabled }) {
    return (
        <div className={styles['input-group']}>
            <input
                type={type}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
            />
            <label>
                {label ? label : 'Lorem Ipsum'}
            </label>
        </div>
    );
}
