export function getDateDiffInDays(data) {

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const dataI = new Date();
    const dataF = new Date(data);

    const utc1 = Date.UTC(dataI.getFullYear(), dataI.getMonth(), dataI.getDate());
    const utc2 = Date.UTC(dataF.getFullYear(), dataF.getMonth(), dataF.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}