import React from "react";
//
import { 
  MdWatchLater,
  MdCheckCircle,
  MdCancel,
  MdInfo,
} from "react-icons/md";
//
import styles from './status-fatura.module.css';

export default function StatusFatura({ fatura }) {

  if(fatura.status === 'RECEIVED' || fatura.status === 'RECEIVED_IN_CASH' || fatura.status === 'CONFIRMED'){
    return (
      <div className={styles['txt-status']}>
        <MdCheckCircle style={{color: 'var(--successM)'}} />
        <span>Pagamento efetuado</span>
      </div>
    );
  }

  if(fatura.status === 'PENDING'){
    return (
      <div className={styles['txt-status']}>
        <MdWatchLater style={{color: 'var(--warningM)'}} />
        <span>Aguardando pagamento</span>
      </div>
    );
  }

  if(fatura.status === 'OVERDUE'){
    return (
      <div className={styles['txt-status']}>
        <MdCancel style={{color: 'var(--errorM)'}} />
        <span>Cobrança Vencida</span>
      </div>
    );
  }

  if(fatura.status === 'REFUND_REQUESTED'){
    return (
      <div className={styles['txt-status']}>
        <MdWatchLater style={{color: 'var(--warningM)'}} />
        <span>Cobrança Solicitada</span>
      </div>
    );
  }

  if(fatura.status === 'REFUNDED'){
    return (
      <div className={styles['txt-status']}>
        <MdInfo style={{color: 'var(--infoM)'}} />
        <span>Cobrança Estornada</span>
      </div>
    );
  }
  
}

