import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
//
import Botao from '../Botao';
import Base64Img from '../Base64Img';
//
import SslImg from '../../assets/site_seguro_imagem.png';
//
import styles from './TelaPix.module.css';

export default function TelaPix({ fatura }) {

  //BODY
  return (
    <section className={styles['container']}>

      <span className={styles['title']}>
        Pix
      </span>

      <div className={styles['pix-container']}>

        <div className={styles['pix']}>

          <div className={styles['qr-code']}>
            <Base64Img
              data={fatura.encodedimage}
              className={styles['qr-code-img']}
              alt='pix'
            />
            <span className={styles['qr-code-txt']}>
              Acesse seu APP de pagamentos e faça a leitura do QR Code ao
              lado para efetuar o pagamento de forma rápida e segura.
            </span>
          </div>

          <div className={styles['btn-pix']}>

            <div className={styles['txt-btn']}>
              <b>Código</b>
              <span>{fatura.payload}</span>
            </div>

            <CopyToClipboard
              text={fatura.payload}
              onCopy={() => { toast.info('Código copiado para a área de transferência!') }}
            >
              <Botao
                type='button'
                onClick={() => { }}
                text='Copiar'
              />
            </CopyToClipboard>

          </div>
        </div>

        <div className={styles['ssl']}>
          <img src={SslImg} alt='site-seguro' />
        </div>

      </div>

    </section>
  )
}
