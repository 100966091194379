import React from 'react'
//
import { BiLoaderAlt } from "react-icons/bi";
//
import styles from './loading-circle.module.css';

export default function LoadingCircle({style}) {
  return (
    <BiLoaderAlt className={styles['loader']} style={{...style}}/>
  )
}
