import React from 'react'
//
import styles from './row.module.css';

export default function Row({ children, style, className }) {
    return (
        <div
            className={styles['row']+' '+(className ? className : '')}
            style={style}
        >
            {children}
        </div>
    )
}
